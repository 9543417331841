import { Controller } from 'stimulus';
import { Client, Account, ID } from 'appwrite';
export default class SigninController extends Controller {
  connect() {
    localStorage.setItem('jwt', $(this.element).data().jwt)
    $.ajax({
      method: 'POST',
      url: '/appwrite/signin_user',
      data: {jwt: $(this.element).data().jwt, init_user: true}
    }).done((data) => {
      Turbolinks.visit('/')
    })
  }
}