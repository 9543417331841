import { Client, Account } from 'appwrite';
import { Controller } from 'stimulus';

export default class OauthController extends Controller {
  connect() {
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
  }

  signin(e) {
    console.log('')
    try {
      this.account.createOAuth2Session(
        $(e.currentTarget).data().provider,
        `${process.env.callbackEndPoint}/registrations/oauth_callback`
      )
    } catch(error) {
      throw error;
    }
  }
}
